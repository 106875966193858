import './App.css';
import Whiteboard from './Whiteboard';

function App() {
  return (
    <div className="App">
      <Whiteboard/>
    </div>
  );
}

export default App;
