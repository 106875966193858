import React, { useState, useEffect } from 'react';
import { Tldraw, useEditor } from 'tldraw';
import './index.css';

function Whiteboard() { // Rename to avoid conflict
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [password, setPassword] = useState('');

    const handleAuthentication = (password) => {
        fetch('https://whiteboard-py-api.cannyware.com/load', {
            headers: {
                'Authorization': 'Basic ' + btoa('username:' + password)
            }
        })
        .then(response => {
            if (response.ok) {
                setIsAuthenticated(true);
                setPassword(password);
            } else {
                alert('Incorrect password! Please try again.');
            }
        })
        .catch(error => {
            console.error('Error authenticating:', error);
            alert('An error occurred while authenticating. Please try again later.');
        });
    };

    return (
        <div style={{ position: 'fixed', inset: 0 }}>
            {isAuthenticated ? (
                <Tldraw persistenceKey="example">
                    <EditorControls password={password} />
                </Tldraw>
            ) : (
                <PasswordPrompt onAuthenticate={handleAuthentication} />
            )}
        </div>
    );
}

function PasswordPrompt({ onAuthenticate }) {
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onAuthenticate(password);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
                <label>
                    Password:
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </label>
                <br />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
}

function EditorControls({ password }) {
    const editor = useEditor();
    const [isSnapshotLoaded, setIsSnapshotLoaded] = useState(false);

    useEffect(() => {
        if (editor && !isSnapshotLoaded) {
            fetch('https://whiteboard-py-api.cannyware.com/load', {
                headers: {
                    'Authorization': 'Basic ' + btoa('username:' + password)
                }
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Failed to load snapshot');
            })
            .then(data => {
                if (data.snapshot) {
                    editor.store.loadSnapshot(data.snapshot);
                    setIsSnapshotLoaded(true);
                }
            })
            .catch(error => console.error('Error loading snapshot:', error));
        }
    }, [editor, isSnapshotLoaded, password]);

    const handleSave = () => {
        if (editor) {
            const snapshot = editor.store.getSnapshot();
            if (snapshot) {
                fetch('https://whiteboard-py-api.cannyware.com/save', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ snapshot })
                })
                .then(response => {
                    if (response.ok) {
                        alert('Snapshot saved');
                    } else {
                        alert('Failed to save snapshot');
                    }
                })
                .catch(error => console.error('Error saving snapshot:', error));
            } else {
                console.error('Snapshot is empty');
            }
        }
    };

    return (
        <div
            style={{
                background: '#a6c1ed',
                padding: '2vh',
                borderRadius: '0.3em',
                position: 'absolute',
                bottom: '45px',
                right: '15px',
                zIndex: 1000,
            }}
            onClick={handleSave}
        >
            Save Snapshot
        </div>
    );
}

export default Whiteboard; // Updated export
